/* SCSS mixins */

// Screen size variables
$screen-xs-min: 425px; // Tiny phones
$screen-sm-min: 576px; // Small tablets and large smartphones (landscape view)
$screen-md-min: 768px; // Small tablets (portrait view)
$screen-lg-min: 992px; // Tablets and small desktops
$screen-xl-min: 1200px; // Large tablets and desktops

// Mixins
@mixin xs {
    @media (min-width: #{$screen-xs-min}) {
        @content;
    }
} // Tiny devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
} // Small devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
} // Medium devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
} // Large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
} // Extra large devices

/* ------------------------------------------------------------------------------------------------- */
/* -------------------------------------------- GENERAL -------------------------------------------- */
/* ------------------------------------------------------------------------------------------------- */

body {
    font-family: ProximaNova, sans-serif;
    // line-height: 1.777;
    // letter-spacing: 0.5px;
    line-height: 1.877;
    letter-spacing: 0.4px;
    font-size: 14px;
}

.container {
    @include xl {
        max-width: 1170px;
        width: 1170px;
    }
}

.container-home {
    @include xl {
        // max-width: 1340px;
    }
}

.badge {
    font-size: 12px;
    color: black;

    &.badge-primary {
        background-color: rgba(51, 122, 183, 0.7);
    }
    &.badge-info {
        background-color: rgba(91, 192, 222, 0.7);
        &.undefined {
            display: none;
        }
    }
    &.badge-danger {
        background-color: rgba(217, 83, 79, 0.7);
    }
    &.badge-warning {
        background-color: rgba(240, 173, 78, 0.7);
    }
    &.badge-success {
        background-color: rgba(92, 184, 92, 0.7);
    }
}

.bundle-icons .sub-rec-nav .fa-chevron-circle-down {
    display: none;
}

.resources-section .row img.bundle-spinner {
    margin: 0;
}

/// 5 column grid for Bootstarp 4
.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}
@media (min-width: 540px) {
    .col-sm-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 720px) {
    .col-md-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 960px) {
    .col-lg-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
@media (min-width: 1140px) {
    .col-xl-2dot4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

a {
    color: #02569a;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: Avalon, sans-serif;
    font-weight: 300;
    margin: 0 auto 2rem;
}

h1,
.h1 {
    font-size: 1.5rem;
    text-align: center;

    @include md {
        font-size: 1.8rem;
        text-align: left;
    }
    @include lg {
        font-size: 1.9rem;
        text-align: left;
    }
    @include xl {
        font-size: 2rem;
        text-align: left;
    }
}

.results-section h1 {
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
}

h2,
.h2 {
    font-size: 1.6rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

h2.sub-header {
    font-size: 2.4rem;

    @include md {
        font-size: 2.8rem;
    }
}

h3 {
    font-weight: 300;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    width: 100%;
}

h4 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

h5 {
    margin-bottom: 1rem;
    float: left;
    width: 100%;
}

h6 {
    font-size: 1.1rem;
}

a.globalFooterCard,
div.globalFooterCard,
a.globalFooterCard1 {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    /*margin: 15px;*/
    z-index: 499;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: center;
    overflow: hidden;
    /*padding: 40px 40px 40px 120px;*/
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
        0 5px 15px rgba(0, 0, 0, 0.07);
    transition-property: color, background-color, box-shadow, transform;
    transition-duration: 0.35s;
    text-decoration: none;
}

a.globalFooterCard1 {
    -ms-flex-direction: column;
    flex-direction: column;
    height: 170px;
    margin-bottom: 20px;
}

a.globalFooterCard:hover,
div.globalFooterCard:hover,
a.globalFooterCard1:hover {
    color: #0068b3 !important;
    transform: translateY(-2px);
    box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1),
        0 8px 15px rgba(0, 0, 0, 0.07);
}

a.globalFooterCard:active,
div.globalFooterCard:active,
a.globalFooterCard1:active {
    transform: translateY(2px);
    box-shadow: 0 11px 20px rgba(50, 50, 93, 0.1),
        0 6px 11px rgba(0, 0, 0, 0.08);
}

a.globalFooterCard1:hover,
a.globalFooterCard1:active {
    background-color: #2e99e6;
    color: #fff !important;
}

a.globalFooterCard1 .icon-m {
    margin: auto;
    margin-top: 10px;
}
a.globalFooterCard1:hover .icon-m,
a.globalFooterCard1:active .icon-m {
    background-position-y: -70px;
}

.globalFooterCard1 h3 {
    font-size: 15px;
    margin-top: 17px;
    flex-grow: 1;
}

.globalFooterCard {
    svg {
        width: 53px;
        height: 53px;
    }
    h3 {
        text-decoration: none;
        margin-bottom: 0;
        margin: 17px 0 0 0;
        font-size: 15px;
        width: auto;
    }
    .arrow {
        margin-top: 17px;
        padding-right: 5px;
    }
    .arrow:after {
        /*content: '\279C';*/
        margin-top: 17px;
        padding-right: 15px;
    }
}

.hover-filling {
    transition: fill 0.15s;
    transition: color 0.1s ease;
}

a.globalFooterCard:hover svg .hover-filling,
div.globalFooterCard:hover svg .hover-filling {
    fill: #337ab7;
}

.card-gray h3 {
    font-size: 15px;
}

/* ----------------- Icons ------------------------ */

.icon-s {
    width: 30px;
    height: 30px;
    margin: 0 6px;
    background-image: url(../images/icons-s.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/icons-s.svg);
    background-size: 600px 60px;
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-block;
}

/* ------------------------------------------------------------------------------------------------- */
/* ---------------------------------------- TOP NAVIGATION ----------------------------------------- */
/* ------------------------------------------------------------------------------------------------- */

header {
    height: 92px;
    transition: height 0.2s ease;
    padding: 0;

    @include sm {
    }
    @include md {
        height: 125px;
        padding: 0.5rem 1rem;
    }
    @include lg {
    }
    @include xl {
    }

    .row {
        width: auto;

        @include md {
            width: 100%;
        }
    }
    .logolink {
        float: left;
        width: 20%;
        padding: 5px 0 0 10px;

        @include md {
            float: none;
            width: auto;
            padding: 0;
        }
    }
    .navbar-right {
        margin-top: -24px;
        color: #5b5b5b;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: right;

        @include sm {
            margin-top: 0;
        }
        @include md {
            margin-top: 16px;
        }
    }
    .navbar-right ul a {
        color: #5b5b5b;
        padding: 6px 10px !important;
        border-radius: 0;
    }
    .search {
        background-position: 0 0;
        margin-top: 30px;
    }
    .search:hover {
        background-position: 0 -30px;
    }
    .menu {
        background-position: -30px -2px;
        margin: 0;
        background-size: auto;
        float: right;
        right: 0;
        position: absolute;
    }
    .menu:hover {
        /*background-position: -30px -30px;*/
    }

    .title-breadc {
        padding-left: 11px;
        display: block;
        float: left;
        width: 80%;
        color: white;
        font-size: 16px;
        margin-top: 6px;

        @include md {
            margin-top: 10px;
            display: inline-block;
            margin-top: 16px;
            float: none;
        }

        &:hover {
            color: white;
            text-decoration: none;
        }

        p:nth-child(1) {
            float: left;
            margin: 0;
            display: block;
            letter-spacing: 0;
            line-height: 25px;
            font-size: 18px;
            font-weight: 600;

            @include md {
                letter-spacing: -0.05em;
                float: none;
                line-height: auto;
            }
        }
        p {
            margin: -5px 0 0 0;
            font-size: 16px;
        }
        p + p {
            display: block;
            float: left;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            display: inline-block;

            @include md {
            }
            @include lg {
                font-size: 16px;
            }
        }
    }

    .undp-head {
        img {
            margin: 0;

            @include md {
                width: 56px;
                margin-top: -8px;
            }
        }
    }

    nav {
        display: inline-block;
        float: right;
        right: 0;
        margin-right: 30px;
        margin-top: 14px;

        @include md {
            margin-top: 20px;
        }
    }
    .navbar-right {
        .nav > li > a {
            padding: 10px;
            color: white;
            font-size: 14px;

            &:hover {
                text-decoration: none;
            }
        }
        .nav-pills li a:hover {
            background-color: transparent;
            color: #eee;
        }
        .nav-pills {
            margin-left: 0;
        }
    }
    .shrink .navbar-right .nav-pills {
        margin-top: 18px;
    }
    .top-menu-search {
        float: right;
        position: absolute;
        right: -34px;
        top: 27px;
        display: none;

        @include md {
            display: block;
        }

        button {
            height: 40px;
            margin-top: -1px;
        }
    }
    .dropdown-menu {
        border-radius: 0;
        box-shadow: 0px 26px 11px -8px rgba(0, 0, 0, 0.1);
        border: none;
        text-align: right;
        min-width: 383px;
        top: 82px;
        right: 0;
        width: 346px;
        left: auto;
        margin-right: -20px;
        padding-bottom: 0;

        @include md {
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            min-width: 300px;
            top: 80px;
            margin-right: 0;
            width: 320px;
            display: none;
        }

        li {
            float: left;
            width: 100%;

            &.nav-top-item,
            &.nav-top-item-last {
                border-bottom: 2px solid #265f91;
                text-transform: uppercase;

                @include md {
                    display: none;
                }
                a {
                    text-transform: uppercase;
                }
            }
            &.nav-top-item-last {
                text-transform: uppercase;

                @include md {
                    display: block;
                }
            }
            &.last-nav-item {
                border-bottom: 0;
            }
        }

        > li > a {
            padding: 12px 20px !important;
            font-weight: 600;
            text-transform: none;
            float: left;
            width: 100%;
            font-size: 14px;
            border-bottom: 1px solid #e2e2e2f3;

            &:hover {
                text-decoration: none;
                background: #f3f3f3;
            }
        }
        .active a {
            background-color: #fff;
            color: #0068b3;
        }
        .active a:hover {
            background-color: #fff;
            color: #0068b3;
        }
        .nav-pills {
            margin-top: 10px;
            display: inline-block;
            margin-right: 10px;
        }
    }
}

.shrink {
    height: 66px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);

    .search {
        margin-top: 18px !important;
    }
}

.navbar-default {
    background-color: rgb(38, 95, 145);
    border-bottom: none;
}
.undp-logo {
    background-image: url(../images/undp-logo.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/undp-logo.svg);
    background-position: top;
    background-size: 76px 154px;
    width: 76px;
    height: 154px;
    font-size: 12px;
    text-decoration: none;
    text-indent: -9999px;
    position: absolute;
    transition: width 0.2s ease;
    transition: height 0.2s ease;
}
.shrink .undp-logo {
    background-image: url(../images/undp-logo.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/undp-logo.svg);
    background-position: top;
    background-size: 66px auto;
    width: 66px;
    height: 66px;
}
.shrink .title-breadc {
    padding-left: 86px;
    margin-top: 9px;
}
.shrink .menu {
    margin-top: 18px !important;
}

/*lifecycle*/

.lifecycle-wrapper {
    .steps li {
        opacity: 0.4;
        transform: scale(0.8);
        box-shadow: 0 45px 60px -50px #000000;
    }

    .steps li:hover {
        opacity: 0.9;
        transform: scale(0.9);
    }

    .steps li.active {
        opacity: 1;
        transform: scale(1);
    }
}

.lifecycle {
    background: no-repeat top;
    background-size: cover;
}
.lifecycle p,
.f-areas p {
    font-size: 16px;
    /*line-height: 1.5em;*/
}

/* ------------------------------------------------------------------------------------------------- */
/* -------------------------------------------- VERT NAV ------------------------------------------- */
/* ------------------------------------------------------------------------------------------------- */

.sub-navblock {
    display: none;
    margin: 0 0 1rem;
    padding-top: 0;
    z-index: 100;
    padding-left: 0;
    padding-right: 0;

    @include md {
        display: block;
        margin-top: 25px;
    }
    @include lg {
        max-width: 285px;
        margin-top: 25px;
    }

    // #navbar-left li:hover,
    // #navbar-left li.active {
    //     background: #337af7;
    // }
    // #navbar-left li:hover > a,
    // #navbar-left li.active > a,
    // #navbar-left li:hover > .menu-folder,
    // #navbar-left li.active > .menu-folder {
    //     color: #fff;
    // }
    .selected {
        background: #0068b3 !important;
        // border-bottom: 1px solid #ddd;
    }
    .selected > a,
    .selected > .menu-folder {
        color: #fff !important;
    }

    .li-depth-0 > a {
        display: none;
    }

    ul {
        display: block;
    }
    .show-more-countries,
    .show-more-subnav {
        font-style: italic;
    }

    .level-0 {
        margin: 0;

        li {
            padding: 1px 12px;
            float: left;
            width: 100%;

            &:hover {
                // background: #f3f3f3;
            }
        }

        > li {
            padding: 0;
            list-style: none;
            margin-bottom: 0;
            background-color: #ddd;
            -webkit-transition: background 0.25s ease-in;
            transition: background 0.25s ease-in;
            border-bottom: 1px solid #fff;
            float: left;
            width: 100%;

            @include lg {
                padding: 0;
            }

            > a {
                // padding: 0.6rem 0 0.4rem;
                padding: 4px 0 4px 20px;
            }
        }
        span {
            float: right;
            padding: 0 0.5rem;
            color: grey;
            cursor: pointer;
            width: 10%;
            display: block;

            &:hover {
                // background: #f3f3f3;
            }
        }
    }

    .li-depth-0 {
        > a {
            border-bottom: 1px solid #eeeeee;
            padding-left: 0.4rem;
            width: 97%;
        }
    }

    .li-depth-1 {
        &:hover {
            background: #0068b3;
            color: white;

            > .level-1 {
                display: block;
                color: white;
            }
        }

        .level-1 {
            left: 262px;
            width: 277px;
            position: absolute;
            background: #dddddd;
            display: none;
            transition: 0.3s;

            @include md {
                left: 228px;
                width: 277px;
            }
            @include lg {
                left: 217px;
                width: 277px;
            }
            @include xl {
                left: 262px;
                width: 277px;
            }
        }
    }

    .li-depth-2 {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
        background-color: #ddd;
        -webkit-transition: background 0.25s ease-in;
        transition: background 0.25s ease-in;
        border-bottom: 1px solid #fff;
        float: left;
        width: 100%;

        &:hover {
            color: white;
            background: #0068b3;

            > .level-2 {
                display: block;
            }
        }

        .level-2 {
            left: 261px;
            width: 285px;
            position: absolute;
            background: #dddddd;
            display: none;
            transition: 0.3s;
            border-left: 1px solid white;
            margin-top: -1px;

            @include md {
                left: 272px;
                width: 277px;
            }
            @include lg {
                left: 261px;
                width: 285px;
            }
            @include xl {
                left: 261px;
                width: 285px;
            }

            .li-depth-3 {
                &:hover {
                    color: white;
                    background: #0068b3;
                }
            }
        }
    }

    .level-3 {
        left: 268px;
        width: 285px;
        display: none;
        transition: 0.3s;
        border-left: 1px solid white;
        position: absolute;
        background: #dddddd;

        @include md {
            left: 280px;
            width: 277px;
        }
        @include lg {
            left: 268px;
            width: 285px;
        }
        @include xl {
            left: 268px;
            width: 285px;
        }

        li {
            a {
                font-size: 0.8rem;
            }
        }
    }

    .li-depth-3 {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
        background-color: #ddd;
        -webkit-transition: background 0.25s ease-in;
        transition: background 0.25s ease-in;
        border-bottom: 1px solid #fff;
        float: left;
        width: 100%;

        &:hover {
            color: white;
            background: #0068b3;

            > .level-3 {
                display: block;
            }
        }

        .level-3 {
            left: 268px;
            position: absolute;
            width: 285px;
            background: #dddddd;
            display: none;
            transition: 0.3s;
            border-left: 1px solid white;
            margin-top: -1px;

            .li-depth-3 {
                &:hover {
                    color: white;
                    background: #0068b3;
                }
            }
        }
    }

    .li-depth-4 {
        // display: none;

        span {
            padding: 0 0.5rem;
        }
    }

    .active {
        // font-weight: bold;
    }

    ul {
        @include md {
            margin-left: 0.3rem;
        }
        @include lg {
            margin-left: 1rem;
        }
    }

    > ul {
        border-right: 0px solid #e6e6e6;
        width: 100%;
        padding: 0 0.5rem 0 0;
        margin: 0;
    }

    li {
        list-style: none;
        margin-bottom: 0;

        &:hover {
            // border-bottom: 1px solid rgb(238, 238, 238);
        }

        a {
            font-size: 12px;
            color: black;
            width: 90%;
            padding: 0.2rem 0 0.17rem 0.5rem;
            float: left;

            &:hover {
                text-decoration: none;
                color: white;
            }

            &.selected {
                color: #0056b3;
                font-weight: bold;
            }
        }
    }

    .page-resources-sidebar {
        margin-top: 2rem;
        display: none;
        width: 98%;

        > div {
            background: linear-gradient(#ddf5ff 1%, #fcfeff);
            float: left;
        }

        h3 {
            font-size: 1.4rem;
            margin: 1rem 0 1rem 0;
            float: left;
        }

        ul {
            margin: 1rem;
        }

        li {
            margin-top: 0.4rem;

            a {
                font-size: 0.8rem;
                color: #0265b5;
                width: 97%;
                display: inline-block;
                padding: 0.1rem;

                &:hover {
                    color: #02569a;
                }
            }

            span {
                float: left;
                margin: 0.2rem 0.2rem 0 0;
            }
        }

        #page-resources-ul {
            text-align: left;
            padding: 0.2rem 0.4rem;

            img {
                width: 42%;
                float: left;
                margin-right: 1rem;
            }

            p {
                padding-top: 0.5rem;
                font-size: 0.9rem;
                margin-right: 0.5rem;
            }
        }
    }
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid white;
        right: 0;
        float: right;
        margin: 8px;
    }
    .li-depth-0 {
        > .caret {
            display: none;
        }
    }
}

.bottom-navigation {
    margin-top: 2rem;
    float: left;
    width: 100%;
    margin-bottom: 2rem;
}
/* ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------- HOMEPAGE -------------------------------------------- */
/* ------------------------------------------------------------------------------------------------- */

.intro-box {
    background: no-repeat;
    height: 190px;

    .btn-primary.btn-outline {
        font-size: 13px;
        font-weight: 400;
        border: 1px solid #428bca;
    }
}
.home-search {
    margin-top: 0;
    margin-bottom: 2rem;
    float: none;
    background-color: #fff;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 0;

    @include md {
        margin-bottom: 3rem;
    }

    .input-group {
        padding: 0;
    }

    button {
        background-position: 9px -25px;
        background-size: auto;
    }
}
.home-search .form-control {
    border: none;
    height: 40px;
}
.home-search .form-control {
    box-shadow: none;
    font-size: 14px;
}
.home-search button {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 5px -25px;
    background-color: #fff;
}
.intro-box .container {
    padding-top: 1rem;
}

.steps {
    width: 100%;
    ul {
        padding-left: 0;
        margin: auto;
        margin-top: 10px;
        justify-content: space-between;
        /*background-image: url(/images/dot.png);*/
        background-repeat: repeat-x;
        background-position: 50%;
        padding: 0;
        width: 100%;
        display: block;

        @include md {
            display: flex;
            width: 89%;
        }
    }
    a > p {
        font-size: 14px;
        font-weight: 400;
        margin: 40px 0 0 0;
    }
    li {
        list-style: none;
        border-radius: 50%;
        display: block;
        transition: transform 0.2s ease;
        float: left;
        width: 45%;
        margin: 2%;

        @include md {
            display: inline-block;
            width: auto;
        }
    }
    li:hover {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
    }
    li a {
        display: inline-block;
        text-decoration: none;
        color: #fff;
        width: 135px;
        height: 135px;
    }
    li a:hover {
    }
    .s-01 {
        background-color: #ffab40;
    }
    .s-02 {
        background-color: #8bc34a;
    }
    .s-03 {
        background-color: #448aff;
    }
    .s-04 {
        background-color: #039be5;
    }
    .s-05 {
        background-color: #ba68c8;
    }
    .s-06 {
        background-color: #ffc400;
    }
}

.lifecycle h1,
.f-areas h1 {
    margin-bottom: 20px;
    font-size: 23px;
}

/* ------------------------------------------------------------------------------------------------- */
/* -------------------------------------------- FOOTER --------------------------------------------- */
/* ------------------------------------------------------------------------------------------------- */

footer {
    background-color: #161616;
    background: #333333;
    padding: 40px 0 10px;
    color: #fff;

    &.impact-footer {
        position: fixed;
        width: 100%;
        bottom: 0;
    }

    .socialmedia-container {
        margin-top: 1.3rem;

        a {
            float: left;
            width: 100%;
            text-align: left;

            &:hover {
                text-decoration: none;
            }

            i {
                font-size: 2rem;
            }
        }
    }

    .copyright,
    a {
        color: white;

        &:hover {
            color: #fff;
        }
        &:visited {
            color: #cacaca;
        }
    }

    .global-footer {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .footer-org-logo {
            width: 105px;
        }

        #mc_embed_signup {
            margin-top: 4rem;

            #mc_embed_signup_scroll {
                float: left;
                width: 100%;

                .mc-field-group {
                    float: left;

                    label {
                        color: #cacaca;
                        margin-bottom: 0;
                    }

                    .email {
                        border: 0;
                        border-radius: 4px;
                        padding: 4px;
                        background: #c5d3de;
                    }
                }

                .button {
                    border: 0;
                    font-family: ProximaNova, sans-serif;
                    padding: 6px;
                    margin-left: 6px;
                    border-radius: 4px;
                    background: #4176ad;
                    text-shadow: none;
                }
            }
        }
    }

    hr {
        clear: both;
        max-width: 75rem;
        height: 0;
        margin: 1.25rem auto;
        border-top: 0;
        border-right: 0;
        border-bottom: 1px solid #cacaca;
        border-left: 0;
    }

    .global-footer .menu-header {
        font-size: 1rem;
        margin-top: 1rem;
        font-family: Lora, serif;
        color: #fefefe;
    }

    .menu {
        padding: 0;
        margin: 0;
        list-style: none;
        position: relative;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-direction: column;
        flex-wrap: nowrap;

        .button,
        a {
            line-height: 1;
            text-decoration: none;
            display: block;
            padding: 0.7rem 0;
            font-size: 0.8rem;
        }
    }

    .bottom-footer {
        background: black;
        padding: 0.5rem 1rem 0.5rem;

        .bottom-menu {
            flex-direction: row;

            li {
                display: block;
                list-style: none;
                margin-left: 1.5rem;
            }
        }

        .copyright {
            font-size: 0.8rem;

            p {
                margin: 0.5rem 0 0;
                padding: 0;
            }
        }
    }
    p {
        margin-bottom: 0.5rem;
    }
    .copy-logo {
        background-image: url(../images/undp-logo-outline.png);
        background-image: linear-gradient(transparent, transparent),
            url(../images/undp-logo-outline.svg);
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 55px auto;
        height: 120px;
        background-position: 15px top;
    }
    .copyright {
        margin: 0 0 20px;

        @include sm {
            margin: 20px 0 0 0;
        }
        @include md {
            margin: 20px 0 0 60px;
        }
        p {
            margin: 0;
        }
    }
    h5 {
        margin-top: 0;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.5);
        margin-top: 20px;
    }
    li {
        list-style: none;
        margin-bottom: 15px;
        font-weight: 700;
        text-decoration: none;
        display: inline-block;
        margin-right: 20px;
        a {
            color: #fff;
            transition: color 0.2s ease;
            &:hover {
                color: #2e99e6;
                text-decoration: none;
            }
        }
    }
    ul {
        padding-left: 0;
        margin-left: 0;
    }
}

.page-content {
    padding: 15px 0 0;
}

/*United Nations Development Programme - Global Fund Partnership Implementation Guidance Manual*/
/*2016 Â© UNDP */

/*WEB FONTS*/

/*COMMON*/
body {
    // font-family: "Open Sans", Helvetica, Arial, Verdana, sans-serif;
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    color: #5b5b5b;
}

.sub-contentblock {
    margin-top: 22px;

    a {
        font-weight: bold;
    }
}
.icon-s {
    width: 30px;
    height: 30px;
    margin: 0 6px;
    background-image: url(/images/icons/icons-s.png);
    background-image: linear-gradient(transparent, transparent),
        url(/images/icons/icons-s.svg);
    background-size: 700px 80px;
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-block;
}
.icon-m {
    width: 70px;
    height: 70px;
    background-image: url(/images/icons/icons-m.png);
    background-image: linear-gradient(transparent, transparent),
        url(/images/icons/icons-m.svg);
    background-size: 1400px 140px;
    background-repeat: no-repeat;
    display: inline-block;
}

h1 {
    font-size: 26px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
}
h2 {
    font-size: 20px;
    font-weight: 600;
}
h3 {
    font-size: 18px;
    font-weight: 400;
}
h5 {
    font-size: 14px;
}

section > h2 {
    margin-top: 0px;
}

table td {
    border: 1px solid #ddd;
    padding: 5px 10px 5px 10px;
}

table thead td {
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
}

.is-page-content a {
    font-weight: bold;
}

.is-page-content ol li,
.is-page-content ul li {
    margin-bottom: 10px;
}

.layout-side-menu {
    float: left;
    width: 270px;
    border-top: 0;
    margin: 45px 0 40px 0;
}

.layout-side-content {
    overflow: hidden;
    padding-left: 25px;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

ul.checklist {
    margin-top: 10px;
}

ul.checklist li {
    list-style: none;
}

ul.checklist li:before {
    content: "âœ”";
    margin-left: -1em;
    margin-right: 0.1em;
}

.cta {
    margin-top: 40px;
}
.button {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background-color: #2e99e6;
    padding: 12px 56px;
    display: inline-block;
    transition: background-color 0.5s ease;
}
.button:hover,
.button:active,
.button:focus {
    color: white;
    background-color: #0068b3;
    text-decoration: none;
    cursor: pointer;
}
.b-gray {
    background-color: #5b5b5b;
}
.b-gray:hover {
    background-color: #444444;
}
.b-white {
    background-color: #fff;
    color: #2e99e6;
    padding: 10px 40px !important;
    margin-top: 30px;
}
.ic-down {
    background-image: url(../images/ic-down.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/ic-down.svg);
    background-repeat: no-repeat;
    background-position: 10px center;
}
.overview,
.lifecycle,
.f-areas {
    padding: 20px 0;
}
.row-centered {
    text-align: center;
}
.col-centered {
    display: inline-table;
    float: none;
    /* reset the text-align */
    text-align: center;
    /* inline-block space fix */
    // margin-right: -15px;
}

/*CONTENT*/

/*Overview*/
.overview .item {
    margin-bottom: 20px;
}
.overview h3 {
    margin-top: 10px;
}

/*functional areas*/

.card-gray {
    padding: 10px 30px 10px 30px;
    margin-top: 30px;
    height: 200px;
    background-color: #fafafa;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    transition: background-color 0.5s ease;
}
.card-gray:hover {
    background-color: #2e99e6;
    color: #fff;
    cursor: pointer;
}
.card-gray:hover .icon-m {
    background-position-y: -70px;
}
.card-gray:hover p {
    /*display: none;*/
}
.card-gray:hover .b-white {
    display: inline-block !important;
}
.card-gray p {
    font-size: 14px;
}

.card-gray1 {
    padding: 10px 30px 10px 30px;
    margin-top: 30px;
    height: 280px;
    background-color: #fafafa;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    transition: background-color 0.5s ease;
}

.ic-001,
.ic-le {
    background-position: 0 0;
}
.ic-002,
.ic-pm {
    background-position: -70px 0;
}
.ic-003,
.ic-fm {
    background-position: -140px 0;
}
.ic-004,
.ic-cd {
    background-position: -210px 0;
}
.ic-005,
.ic-sm {
    background-position: -280px 0;
}
.ic-006,
.ic-rm {
    background-position: -350px 0;
}
.ic-007,
.ic-ae,
.ic-ai {
    background-position: -420px 0;
}
.ic-008,
.ic-me {
    background-position: -490px 0;
}
.ic-009,
.ic-hh {
    background-position: -560px 0;
}
.ic-010,
.ic-hr {
    background-position: -630px 0;
}

.card-gray:hover .ic-011 {
    background-image: url(../images/ic-w-011.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/ic-w-011.svg);
    background-position-y: 0px;
}

.ic-011,
.ic-cm {
    width: 70px;
    height: 70px;
    background-image: url(../images/ic-011.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/ic-011.svg);
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 70px 70px;
}

.ic-st {
    width: 70px;
    height: 70px;
    background-image: url(../images/ic-start-up.png);
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 70px 70px;
}

.ic-rp {
    width: 70px;
    height: 70px;
    background-image: url(../images/ic-reporting.png);
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 70px 70px;
}

.ic-gc {
    width: 70px;
    height: 70px;
    background-image: url(../images/ic-closure.png);
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 70px 70px;
}

/*FOOTER*/
.sn-fb,
.sn-tw,
.sn-ig {
    width: 36px;
    height: 36px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 5px 0 0;
}
.sn-fb {
    background-image: url(../images/ic-sn-fb.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/ic-sn-fb.svg);
    background-position: center;
    background-color: #448ccb;
}
.sn-tw {
    background-image: url(../images/ic-sn-tw.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/ic-sn-tw.svg);
    background-position: center;
    background-color: #6dcff6;
}
.sn-ig {
    background-image: url(../images/ic-sn-ig.png);
    background-image: linear-gradient(transparent, transparent),
        url(../images/ic-sn-ig.svg);
    background-position: center;
    background-color: #aaaaaa;
}

/*RESPONSIVE*/

/*1200px desktop*/
@media (max-width: 1200px) {
    .navbar-right .nav-pills {
        display: none;
    }
    .title-breadc p + p {
    }
    .sn-fb,
    .sn-tw,
    .sn-ig {
        margin: 0;
    }
}
@media (min-width: 1200px) {
    header .dropdown-menu .nav-pills,
    header .dropdown-menu .divider {
        display: none;
    }
}
/*991*/
@media (max-width: 991px) {
}

/*768px*/
@media (max-width: 768px) {
    .title-breadc {
        font-size: 14px;
    }
    .title-breadc p + p {
        font-size: 14px;
    }
}

/*661*/
@media (max-width: 661px) {
    .title-breadc p {
        display: none;
    }
}
/*435px nexus 6*/
@media (max-width: 435px) {
    .icon-s {
        margin: 0;
    }
    .undp-head {
        margin-left: -15px;
    }
    .undp-logo {
        background-position: top;
        background-size: 66px auto;
        width: 66px;
        height: 66px;
    }
    .title-breadc,
    .shrink .title-breadc {
        padding-left: 76px;
        margin-top: 9px;
    }
    header .search,
    header .menu {
        margin-top: 18px !important;
    }
    .undp-nav {
        padding-right: 0;
    }
    .f-areas .card-gray {
        margin-top: 7px;
        margin-bottom: 7px;
    }
}
/*360*/
@media (max-width: 360px) {
    .icon-s {
        margin: 0;
    }
    .title-breadc p + p {
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
        line-height: 13px;
    }
}

/*320 iphone5*/
@media (max-width: 320px) {
    .icon-s {
        margin: 0;
    }
    .title-breadc p + p {
        font-size: 10px;
        font-weight: 400;
        display: inline-block;
        line-height: 12px;
    }
}

.breadcrumb-box {
    padding: 40px 0;
}

.breadcrumb-box .container {
    padding-top: 47px;
}

.breadcrumb-box .container-fluid {
    padding-left: 100px;
}

.breadcrumb-box h1 {
    padding-top: 20px;
    color: #333333;
    font-weight: bold !important;
    font-size: 28px;
    letter-spacing: 0px;
}

.c-page-breadcrumbs {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.c-page-breadcrumbs > li {
    display: inline-block;
    margin: 0;
    padding: 8px 4px 8px 4px;
}

.is-page-content section {
    padding: 40px 0;
}

a.card-gray {
    display: block;
}

a.card-gray:hover {
    text-decoration: none;
}

/*Search*/
.search-filter {
    height: 80px;
    background-color: #fff;
}
.search-result {
    background-color: #fafafa;
    // padding: 20px 0 50px 0;
    padding: 0;

    .container2 {
        display: none;
    }
}

.loading-subrec {
    background-color: white;
    padding: 20px 0 50px 0;
    text-align: center;
    border-top: 1px solid #bfbfbf;

    img {
        width: 80px;
    }
}

.resources-section {
    background-color: #dadada;
    padding: 20px 0 50px 0;
}

.resources-section .resources {
    font-size: 24px;
    font-weight: bold;
}

.search-result .row,
.resources-section .resource-item {
    background-color: #fff;
    margin: 5px 0;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
    min-height: 70px;
    &.row-hidden {
        display: none;
        margin-bottom: 10px;
        background-color: #eee;
        .row {
            background-color: #eee;
        }
    }
}
.resources-section {
    .bundled-items {
        display: none;
        .resource-item {
            width: 95%;
            background-color: #eee;
        }
    }
}
.search-result .row:hover,
.resources-section .resource-item:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
}
.search-result .row img,
.resources-section .row img {
    margin: 20px 7px;
}
.docs-icon {
    display: none;

    @include sm {
        width: 56px;
        display: block;
    }
}
.docs-title {
    font-size: 16px;
    font-weight: 600;
    margin: 14px 0 0 0;
    line-height: normal;
}
.docs-title .type-doc {
    color: #5b5b5b;
}
.docs-title .type-link {
    color: #2e99e6;
    cursor: auto;
}
.docs-title a {
    color: #2e99e6;
    text-decoration: none;
}
.docs-title a:hover {
    color: #2e99e6;
}
.docs-info p {
    color: #a1a1a1;
}
.docs-info p,
.docs-info p {
    display: inline-block;
}
.docs-info span {
    background-color: #d5ebfa;
    color: #212121;
    padding: 0 3px;
}
.docs-info .lang {
    color: #898989;
    font-weight: 600;
    margin: 0 8px;
}
.docs-info .lang:hover {
    color: #2e99e6;
}

.docs-info1 {
    line-height: normal;

    p {
        margin-bottom: 0.3rem;
        line-height: normal;
    }
}

.i-doc-title {
    font-weight: bold;
}

.row-highlight {
    background-color: #ccc;
    margin-bottom: 10px;
}

.row-hidden {
    display: none;
    margin-bottom: 10px;
    background-color: #eee;
}

.bundle-icons .btn-toolbar {
    float: right;
}

.resource-item {
    .btn-group {
        float: right;
    }

    p {
        margin-bottom: 0.3rem;
    }

    .hidden {
        display: none;
    }
}

/*practice pointer*/

.ppointer {
    margin: 20px auto 30px;
    font-size: 16px;
    background: white;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    /*-ms-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;*/
    border: 1px solid #0068b3;
}

.ppointer-h {
    display: flex;
    align-items: center;
    background-color: #0068b3;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    text-align: center;
    padding: 5px 5px;
    color: white;
    min-width: 80px;
}

.ppointer-h span {
    display: block;
}

.ppointer-b {
    font-family: Helvetica, Arial, Verdana, sans-serif;
    font-size: 13px;
    padding: 0.8rem 1rem;

    .ppointer-h {
        display: none;
    }
}

.ppointer-b p {
    padding: 10px 10px;
    margin-bottom: 0;
    line-height: 1.5;
    letter-spacing: normal;
    font-size: 15px;
}

.ribbon-wrapper-green {
    display: none;
}

b,
strong {
    font-weight: bold;
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.stage-wrapper {
    margin-top: 40px;
}

/********* navbar left ************/

#navbar-left ul,
#navbar-left li {
    list-style: none;
    margin: 0;
    padding: 0;
}

#navbar-left ul li:hover {
    position: relative;
    z-index: 599;
}

#navbar-left li {
    background-color: #ccc;
}

#navbar-left ul ul li {
    background-color: #ccc;
}

#navbar-left ul ul ul li {
    background-color: #bbb;
}

#navbar-left ul ul {
    top: 0;
    left: 100%;
    z-index: 598;
    min-width: 270px;
}

#navbar-left li:hover > ul {
    display: block;
}

#navbar-left .menu-folder {
    cursor: pointer;
}

#navbar-left a,
#navbar-left .menu-folder {
    display: inline-block;
    font-size: 12px;
    text-decoration: none;
    color: #1a1a1a;
    line-height: 160%;
    padding: 5px 8px 5px 20px;
    width: 255px;
    right: 10px;
}

#navbar-left li {
    background-color: #ddd;
    -webkit-transition: background 0.25s ease-in;
    transition: background 0.25s ease-in;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
}

#navbar-left li:hover,
#navbar-left li.active {
    background: #337af7;
}
#navbar-left li:hover > a,
#navbar-left li.active > a,
#navbar-left li:hover > .menu-folder,
#navbar-left li.active > .menu-folder {
    color: #fff;
}
#navbar-left .selected {
    background: #0068b3;
    border-bottom: 1px solid #ddd;
}
#navbar-left .selected > a,
#navbar-left .selected > .menu-folder {
    color: #fff;
}

.caret-right {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid white;
}

#navbar-left li:hover > .caret-right,
#navbar-left li.active > .caret-right,
#navbar-left .selected > .caret-right {
    border-left: 6px solid white;
}

/******** project lifecycle  *****/

.stage {
    display: none;
}

.stage-name {
    font-size: 18px;
    font-weight: bold;
}

.stage-desc {
    margin-top: 20px;
    margin-bottom: 20px;
    clear: both;
}
/***imported*/
.timeline {
    list-style: none;
    padding: 20px 0 0;
    position: relative;
}

.timeline:before {
    top: 25px;
    bottom: 30px;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 4%;
    margin-left: -1.5px;
}

.timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    width: 91%;
    float: left;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 10px 10px 0px 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    /*height: 50px*/
}

.timeline > li > .timeline-panel:before {
    position: absolute;
    top: 8px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
}

.timeline > li > .timeline-panel:after {
    position: absolute;
    top: 9px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}

.timeline > li > .timeline-badge {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    left: 4%;
    margin-left: -25px;
    background-color: #999999;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline > li > .timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline > li > .timeline-panel {
        float: right;
    }

    ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
}

.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}

.bs-callout-info {
    border-left-color: #1b809e;
}

.bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
}

.bs-callout-info h4 {
    color: #1b809e;
}

/***** thee column lists ***/
.col-md-4 ol {
    margin-left: 0px;
    padding-left: 5px;
}

.col-md-4 ol li {
    font-size: 12px;
    margin: 0px;
}

/**** error in links .error{background-color:red;} *****/

/*********search filters**********/
.stylish-input-group .input-group-addon {
    background: white;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-top: 4px;
    padding-right: 6px;
    border-radius: 0 4px 4px 0;
}
.stylish-input-group .form-control {
    border-right: 0;
    box-shadow: 0 0 0;
    border-color: #ccc;
}
.stylish-input-group button {
    border: none;
    outline: none;
    background: transparent;
}

.single-selects .select2-container,
.single-selects .select2-container input {
    min-width: 420px;
    max-width: 400px;
}
.multi-selects .select2-container input,
.multi-selects2 .select2-container input {
    min-width: 200px;
    max-width: 290px;
}
.multi-selects .select2-container,
.multi-selects2 .select2-container {
    min-width: 200px;
    max-width: 290px;
}

.select2-container {
    margin-right: 20px;
    vertical-align: top;
}

.single-selects div,
.multi-selects div {
    display: inline-block;
}

#res_type .select2-container--default .select2-selection--multiple {
    border: 1px solid #2e99e6;
    outline: none;
}
#funct_area .select2-container--default .select2-selection--multiple {
    border: 1px solid #2e99e6;
}
#prj_stage .select2-container--default .select2-selection--multiple {
    border: 1px solid #2e99e6;
}
#topic .select2-container--default .select2-selection--multiple {
    border: 1px solid #2e99e6;
}

#topic .select2-container,
#topic input {
    max-width: 400px;
    width: 400px !important;
}

.home-search,
#filters {
    opacity: 0.8;
}

#reset-filters {
    margin-bottom: 10px;
}

/*** dim lables **/

.docs-info .label,
.docs-info1 .label {
    color: black;
}

.label-warning {
    background-color: rgba(240, 173, 78, 0.7);
}

.label-danger {
    background-color: rgba(217, 83, 79, 0.7);
}

.label-info {
    background-color: rgba(91, 192, 222, 0.7);
}

.label-primary {
    background-color: rgba(51, 122, 183, 0.7);
}

.label-success {
    background-color: rgba(92, 184, 92, 0.7);
}

.search-bar {
    .input-group-addon {
    }
}
/*********** button outline    ********/

.btn-outline {
    background-color: transparent;
    color: inherit;
    transition: all 0.5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

a.btn-success,
.btn-success {
    color: white;
}

.stage-step {
    .btn {
        padding: 0 25px;
    }
}
.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-info {
    background-color: #5bc0de;
    border-color: #46b8da;
    font-size: 14px;
}
a.btn-info {
    color: white;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

p,
div,
li,
td,
th {
    page-break-inside: avoid !important;
}

.overview .card-gray {
    color: #5b5b5b;
}
.overview .card-gray:hover {
    color: #5b5b5b;
    background-color: #eee;
}

.treeview .list-group-item {
    padding: 5px 10px;
    cursor: pointer;

    .node-selected {
        color: rgb(255, 255, 255);
        background-color: rgb(66, 139, 202);
        display: list-item;
    }
}
.node-tree {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.badge-secondary {
    color: white;
}
.node-tree div {
    flex-grow: 1;
    width: 77%;
    float: left;
}
.node-tree .icon {
    display: none;
}
.node-tree .badge {
    width: auto;
    min-width: auto;
    margin: 3px 0;
    float: right;
}
.node-tree .expand-icon {
    display: block;
    top: -1px;
}
.node-tree .indent {
    margin-right: 0px !important;
}

/***new buttons*/

.common-Button {
    white-space: nowrap;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: #6772e5;
    text-decoration: none;
    transition: all 0.15s ease;
}
.common-Button:hover {
    color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.common-Button:active {
    color: #555abf;
    background-color: #f6f9fc;
    transform: translateY(1px);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.common-Button--default {
    color: #fff;
    background: #6772e5;
}
.common-Button--default:hover {
    color: #fff;
    background-color: #7795f8;
}
.common-Button--default:active {
    color: #e6ebf1;
    background-color: #555abf;
}

#main-wrapper {
    margin-top: 25px;
}

.resources-page {
    margin-bottom: 4rem;

    .rec-holder {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        transform: translateY(0px);
        background: none;
        box-shadow: none;
        margin-bottom: 2rem;
        &:hover {
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
            transform: translateY(0px);
            background: none;
            box-shadow: none;
        }

        .col-md-3 {
            padding: 0;
        }

        .container-recs,
        .repo-search-result {
            padding: 0;

            @include md {
                padding: 0 0.5rem;
                width: 100%;
            }
        }

        .repo-search-results {
            margin-top: 1rem;

            @include md {
                margin-top: 0;
            }
        }
    }
    ul {
        margin-left: 0;
    }
    .list-group-item {
        display: none;
        list-style: none;

        &.rec-toggle {
            display: block;
        }

        .toggle-icon {
            float: left;
            margin: 6px 5px 0 0;

            &.fa-minus {
                display: none;
            }
        }
    }
    .panel-title {
        font-weight: 400;
    }
    .badge,
    .i-doc-title {
        font-weight: 400;
        font-size: 11px;
        margin-bottom: 4px;
    }
    .search-result {
        .row {
            background-color: #fff;
            margin: 5px 0;
            box-shadow: 0px 2px 5px 1px rgb(208, 208, 208);
            min-height: 70px;
        }
    }
    .docs-title .type-doc {
        color: #5b5b5b;
        margin-bottom: 0.3rem;
        float: left;
        width: 100%;
    }
}

.rec-toggle {
    cursor: pointer;
}

.panel-topic .panel-heading {
    background-color: #5bc0de;
    opacity: 0.8;
}

.panel-default > .panel-heading {
    color: #333;
    // background-color: #f5f5f5;
    // border-color: #ddd;
}
.search-bar {
    .panel-default {
        border-color: #ddd;

        > .panel-heading {
            color: #333;
            background-color: #f5f5f5;
            border-color: #ddd;
        }

        .panel-body {
            opacity: 0.8;
            text-align: center;
        }

        .dropdown {
            width: 33%;
            margin-bottom: 1rem;
        }

        #filters {
            opacity: 1;
        }
    }
}
.panel-group .panel-heading {
    border-bottom: 0;
}
.panel-heading {
    cursor: pointer;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel-restype .panel-heading {
    background-color: #f0ad4e;
    opacity: 0.8;
}
.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}

.panel-default {
    border-color: #ddd;
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

/*************FAQs**************/

.faqs {
    list-style: none;
}

.faqs li {
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.faqs h4 {
    font-weight: bold;
    color: #337ab7;
}

.faqs .answer {
    color: darkgrey;
}

/***************team page****/
.team {
    display: flex;
    flex-flow: row;
}
.profile-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: auto;
    text-align: center;
}

.profile-container {
    padding: 0 16px;
}

.profile-title {
    color: grey;
    font-size: 18px;
}

.profile-card button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}

.profile-card a {
    text-decoration: none;
    font-size: 22px;
    color: black;
}

.profile-card button:hover,
.profile-card a:hover {
    opacity: 0.7;
}

.panel-default {
    border-color: #ddd;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}
.panel-default > .panel-heading {
    color: #333;
    // background-color: #f5f5f5;
    // border-color: #ddd;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.home-search,
#filters {
    opacity: 0.8;
}
.panel-body {
    padding: 15px;
}

.single-selects div,
.multi-selects div {
    display: inline-block;
}
select[multiple],
select[size] {
    height: auto;
}
.single-selects div,
.multi-selects div {
    display: inline-block;
}
.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
select {
    text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}
.multi-selects .select2-container,
.multi-selects2 .select2-container {
    min-width: 200px;
    max-width: 290px;
}

.select2-container {
    margin-right: 20px;
    vertical-align: top;
}
.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}
.container-recs {
    .panel-default {
        margin-bottom: 4px;
    }
    .panel-restype {
        margin-bottom: 4px;
    }
    .topic-holder,
    .resource-holder {
        display: none;
    }
}
#funct_area .select2-container--default .select2-selection--multiple {
    border: 1px solid #2e99e6;
}
.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
}
.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__rendered
    li {
    list-style: none;
}

.select2-container .select2-search--inline {
    float: left;
}
.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield;
}
.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0;
}
.multi-selects .select2-container input,
.multi-selects2 .select2-container input {
    min-width: 200px;
    max-width: 290px;
}
/**** spinner ****/
.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

div.timeline {
   padding-bottom: 0; 
}

ul {
    li {
        p {
            margin-bottom: 0.2rem;
            margin-top: 0.4rem;
        }

        ol {
            margin-bottom: 0.6rem;
        }
    }
}